import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Parent from "./Parent";
import Student from "./Student";
import SGgrades from "./SGgrades";
import "./App.css";

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path="/parent-portal">
            <Parent />
          </Route>
          <Route path="/sg-grades">
            <SGgrades />
          </Route>
          <Route path="/">
            <Student />
          </Route>
        </Switch>
      </Router>
    </>
  );
};

export default App;
