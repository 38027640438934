import React from "react";
import { Row, Col, List, Card } from "reactstrap";
import TutoQuiz3 from "./assets/TutoQuiz3.svg";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineYoutube,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { RiTwitterLine } from "react-icons/ri";
import { MdCall, MdChat } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";

const Footer = () => {
  return (
    <>
      <Card style={{ backgroundColor: "#231F20", padding: "30px" }}>
        <Row>
          <Col
            xs="12"
            lg="3"
            md="3"
            className="align-items-start justify-content-lg-center justify-content-md-center justify-content-xs-start justify-content-sm-start d-flex"
          >
            <img src={TutoQuiz3} className="img-fluid" alt="" />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mt-3">
          <Col xs="12" lg={{ size: 3, offset: 3 }} md={{ size: 3, offset: 3 }}>
            <List type="unstyled">
              <h5 className="footerTitle font-weight-bold">Navigation</h5>
              <ul className="list-unstyled">
                <li className="text-light mt-3">
                  <Link to="/" className="text-light text-decoration-none">
                    Home
                  </Link>
                </li>
                <li className="text-light mt-1">
                  <Link
                    to="/parent-portal"
                    className="text-light text-decoration-none"
                  >
                    Parent
                  </Link>
                </li>
                <li className="text-light mt-1">
                  <Link
                    to="/sg-grades"
                    className="text-light text-decoration-none"
                  >
                    Grades
                  </Link>
                </li>
              </ul>
            </List>
          </Col>
          <Col xs="12" lg="3" md="3">
            <List type="unstyled">
              <h5 className="footerTitle font-weight-bold">Features</h5>
              <ul className="list-unstyled">
                <li className="text-light mt-3">
                  <a
                    href="https://app.tutoquiz.com/signup"
                    className="text-light text-decoration-none"
                  >
                    Dashboard
                  </a>
                </li>
                <li className="text-light mt-1">
                  <a
                    href="https://app.tutoquiz.com/login"
                    className="text-light text-decoration-none"
                  >
                    Questions and Answers
                  </a>
                </li>
                <li className="text-light mt-1">
                  <a
                    href="https://app.tutoquiz.com/signup"
                    className="text-light text-decoration-none"
                  >
                    Grade Levels
                  </a>
                </li>
              </ul>
            </List>
          </Col>
          <Col xs="12" lg="3" md="3">
            <List type="unstyled">
              <h5 className="footerTitle font-weight-bold">Support</h5>
              <ul className="list-unstyled">
                <li className="text-light mt-3">
                  <a href="info@smactechlabs.com">
                    <HiOutlineMailOpen
                      color="#00CCCC"
                      size="20"
                      className="mr-3"
                    />
                  </a>
                  Mail Support
                </li>
                <li className="text-light mt-1">
                  <MdCall color="#00CCCC" size="20" className="mr-3" /> Call
                  Support
                </li>
                <li className="text-light mt-1">
                  <MdChat color="#00CCCC" size="20" className="mr-3" />
                  Chat Support
                </li>
              </ul>
            </List>
          </Col>
          <Row className="mt-4">
            <Col
              xs={{ size: 12 }}
              lg={{ size: 3, offset: 9 }}
              md={{ size: 3, offset: 9 }}
              className="socialIcons"
            >
              <a href="https://www.facebook.com/TutoQuiz/">
                <AiOutlineFacebook
                  color="#00CCCC"
                  size="28"
                  className="FBIcon"
                />
              </a>
              <a href="https://www.instagram.com/tutoquiz/">
                <AiOutlineInstagram
                  color="#00CCCC"
                  size="28"
                  className="ml-3 mr-3 InstaIcon"
                />
              </a>

              <a href="https://twitter.com/TutoQuiz">
                <RiTwitterLine
                  color="#00CCCC"
                  size="28"
                  className="twitterIcon"
                />
              </a>
              <a href="https://www.linkedin.com/company/tuto-quiz/">
                <AiOutlineLinkedin
                  color="#00CCCC"
                  size="28"
                  className="ml-3 mr-3 LinkedInIcon"
                />
              </a>
              <a href="https://www.youtube.com/channel/UCVwqjQvBzt4CeJfCiutyGcw">
                <AiOutlineYoutube
                  color="#00CCCC"
                  size="32"
                  className="youTubeIcon"
                />
              </a>
            </Col>
          </Row>
        </Row>
      </Card>
      <Row style={{ backgroundColor: "#00A4AA" }}>
        <Col
          xs={{ size: 12 }}
          lg={{ size: 10, offset: 1 }}
          md={{ size: 10, offset: 1 }}
          className="my-3 d-flex align-items-center justify-content-center text-center"
        >
          <Row>
            <Col xs="12" lg="3" md="3" className="text-light endFooter">
              @ Copyright 2021 TUTOQUIZ
            </Col>
            <Col xs="12" lg="2" md="2" className="text-light endFooter">
              All Rights Reserved
            </Col>
            <Col xs="12" lg="2" md="2" className="text-light endFooter">
              Privacy Policy
            </Col>
            <Col xs="12" lg="2" md="2" className="text-light endFooter">
              Disclaimer
            </Col>
            <Col xs="12" lg="3" md="3" className="text-light">
              Terms and Conditions
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Footer;
