import React, { useState, useLayoutEffect } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // Button,
  Button,
  // Container,
} from "reactstrap";
import { NavLink as Link } from "react-router-dom";
import logo from "./assets/Tuto-Quiz.svg";

const DefaultNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="stickyNav bgForNav w-100">
      {/* <Container fluid> */}
      <Navbar expand="md" className="App-logo">
        <NavbarBrand tag={Link} to="/">
          <img src={logo} alt="logo" height="40" width="180" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto ml-4" navbar>
            <NavItem>
              <NavLink tag={Link} to="/parent-portal">
                Parent
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={Link} to="/sg-grades">
                Grades
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <a href="https://app.tutoquiz.com/login" className="nav-buttons">
                <Button className="navLoginBtn login_trial_btn">
                  <span className="login-text">Log In</span>
                </Button>
              </a>
            </NavItem>
            <NavItem className="BtnItems">
              <a
                href="https://app.tutoquiz.com/signup"
                className="nav-buttons mr-2 border-left"
              >
                <Button className="navLoginBtn login_trial_btn">
                  <span className="login-text">Sign Up</span>
                </Button>
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      {/* </Container> */}
    </div>
  );
};

export default DefaultNavbar;
