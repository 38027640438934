import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardImg,
  CardGroup,
  CardSubtitle,
  CardBody,
  CardTitle,
  CardHeader,
  CardFooter,
  CardText,
} from "reactstrap";
import StudentBanner1 from "./assets/StudentBannerA.jpg";
import StudentBanner2 from "./assets/StudentBannerB.jpg";
import StudentBanner3 from "./assets/StudentBanner3.jpg";
import StudentBanner4 from "./assets/StudentBanner4.jpg";
import dailyQuizBg from "./assets/dailyQuizBg.png";
import dailyQuizImg from "./assets/dailyQuizImg.jpg";
import dashboardBg1 from "./assets/dashboardBg1.png";
import studentDashboard from "./assets/studentDashboard.png";
import TestimonialsBg from "./assets/TestimonialsBg.jpg";
import child from "./assets/child.svg";
import TQMonogram from "./assets/TQMonogram.png";
// import bg_img_2 from "./assets/bg-img-2.jpg";
import card_icons_1 from "./assets/overlapCard1.png";
import card_icons_2 from "./assets/overlapCard2.png";
import card_icons_3 from "./assets/overlapCard3.png";
// import cards_Down_img from "./assets/cards-Down img.png";
import science from "./assets/science.svg";
import english from "./assets/english.svg";
import maths from "./assets/maths.png";
import { FaRegHandshake, FaGraduationCap, FaRegGem } from "react-icons/fa";

import DefaultNavbar from "./Navbar";
import Footer from "./Footer";

const Student = () => {
  return (
    <>
      <DefaultNavbar />

      <Row className="alignImgText">
        <Col
          xs="12"
          lg="6"
          md="6"
          className="px-0 d-flex align-items-center justify-content-center pb-4"
          style={{
            backgroundImage: `url(${StudentBanner1})`,
            backgroundPosition: "100% 100%",
            objectFit: "cover",
            backgroundSize: "cover",
          }}
        >
          <div className="textHeight">
            {/* <span className="studentBannerText">
              Enter the world of
              <br />
              <b
                className="text-uppercase font-weight-bolder mt-4"
                style={{ fontSize: "60px" }}
              >
                <span>Tutoquiz</span>
              </b>{" "}
              to see
              <br />
              your grades{" "}
              <strong className="highlightText">
                improve
                <br />
                magically
              </strong>
            </span> */}
            <span className="studentBannerText">
              Enter the world of
              <br />
              <b
                className="text-uppercase font-weight-bolder tutoTitle"
                // style={{ fontSize: "60px", lineHeight: "60px" }}
              >
                <span>Tutoquiz</span>
              </b>{" "}
              <br />
              to see your grades
              <strong className="highlightText">
                <br />
                improve magically
              </strong>
            </span>
            <br />
            <br />
            <small className="text-light mt-5 font-weight-bold">
              Tutoquiz applies Artificial Intelligence to post
              <br />
              the right questions based on your skill level.
            </small>
            <br />
            <a href="https://app.tutoquiz.com/signup" className="">
              <Button className="mt-5 px-5 py-3 getStartedButton">
                <b style={{ fontSize: "17px" }}>Get Started</b>
              </Button>
            </a>
          </div>
        </Col>
        <Col className="px-0" xs="12" lg="6" md="6">
          <img src={StudentBanner2} className="img-fluid" alt="banner" />
        </Col>
      </Row>

      <CardGroup className="overlapCards overlapBg">
        <Row className="align-items-center justify-content-center">
          <Col xs="10" lg="3" md="3" className="mt-2">
            <Card className="overlapCardsAlign">
              <CardImg
                top
                width="100%"
                src={card_icons_1}
                alt="Card image cap"
                className="rounded mx-auto d-block overlapImg"
              />
              <CardBody>
                <CardText className="overlapText">
                  Self Help with Custom Dashboards
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs="10" lg="3" md="3" className="mt-2">
            <Card className="overlapCardsAlign">
              <CardImg
                top
                width="100%"
                src={card_icons_2}
                alt="Card image cap"
                className="rounded mx-auto d-block overlapImg"
              />
              <CardBody>
                <CardText className="overlapText">
                  Parent Supervision with Parent App
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col xs="10" lg="3" md="3" className="mt-2">
            <Card className="overlapCardsAlign">
              <CardImg
                top
                width="100%"
                src={card_icons_3}
                alt="Card image cap"
                className="rounded mx-auto d-block overlapImg"
              />
              <CardBody>
                <CardText className="overlapText">
                  Expert Guidance with Online Tutor
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </CardGroup>

      <Row
        style={{
          backgroundImage: `url(${dashboardBg1})`,
          backgroundPosition: "100% 100%",
          objectFit: "cover",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "70vh",
          marginTop: "5%",
        }}
        className="alignImgText"
      >
        <Col
          xs="12"
          sm="12"
          lg="6"
          md="6"
          className="d-flex align-items-center justify-content-center text-center"
        >
          <div className="textHeight">
            <span className="studentBannerText1">
              <strong className="text-info highlightText">Custom built</strong>
              <br />
              dashboards
              <br />
              to keep track of your
              <br />
              <b className="text-info highlightText">progress closely</b>
            </span>
            <br />
            <a href="https://app.tutoquiz.com/login" className="">
              <Button className="mt-5 px-5 py-3 chooseSkillsBtn">
                <b>Check Your Skills</b>
              </Button>
            </a>
          </div>
        </Col>
        <Col
          xs="12"
          sm="12"
          lg="5"
          md="5"
          className="d-flex align-items-center justify-content-center text-center"
        >
          <img
            src={studentDashboard}
            className="img-fluid"
            alt="dailyQuizImg"
          />
        </Col>
      </Row>

      <Row>
        <Col xs="12" lg="6" md="6" className="px-0">
          <img src={StudentBanner3} alt="img" className="img-fluid" />
        </Col>
        <Col
          xs="12"
          lg="6"
          md="6"
          className="px-0 d-flex align-items-center justify-content-center text-center pb-4"
          style={{
            backgroundImage: `url(${StudentBanner4})`,
            backgroundPosition: "100% 100%",
            objectFit: "cover",
            backgroundSize: "cover",
          }}
        >
          <div className="textHeight">
            <span className="studentBannerText">
              It is easier to
              <br />
              <b className="text-warning highlightText">
                build stronger children
              </b>
              <br /> than to
              <b className="text-warning highlightText"> repair broken men</b>
            </span>
            <br />
            <a href="https://app.tutoquiz.com/signup" className="">
              <Button className="mt-5 px-5 py-3 getStartedButton">
                <b>Explore Quiz</b>
              </Button>
            </a>
          </div>
        </Col>
      </Row>

      <div className="">
        <Row>
          <Col
            xs="12"
            lg="12"
            md="12"
            className="d-flex align-items-center justify-content-center mt-5"
          >
            <CardTitle
              tag="h2"
              style={{ color: "#0E434A" }}
              className="font-weight-bold"
            >
              Start testing your skills
            </CardTitle>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-5">
          <Col
            xs="12"
            lg="3"
            md="3"
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              <Card className="scienceCard m-5">
                <CardImg src={science} className="img-fluid" />
                <CardTitle tag="h4" className="parentCardTitle">
                  Science
                </CardTitle>
              </Card>
              <div className="text-center">
                <a
                  href="https://app.tutoquiz.com/signup"
                  className="text-decoration-none"
                >
                  <Button color="info" className="px-5 font-weight-bold">
                    Join
                  </Button>
                </a>
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            lg="3"
            md="3"
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              <Card className="englishCard m-5">
                <CardImg src={english} className="img-fluid" />
                <CardTitle tag="h4" className="parentCardTitle">
                  English
                </CardTitle>
              </Card>
              <div className="text-center">
                <a
                  href="https://app.tutoquiz.com/signup"
                  className="text-decoration-none"
                >
                  <Button color="danger" className="px-5 font-weight-bold">
                    Join
                  </Button>
                </a>
              </div>
            </div>
          </Col>
          <Col
            xs="12"
            lg="3"
            md="3"
            className="d-flex align-items-center justify-content-center"
          >
            <div>
              <Card className="mathsCard m-5">
                <CardImg src={maths} alt="" className="img-fluid" />
                <CardTitle tag="h4" className="parentCardTitle">
                  Maths
                </CardTitle>
              </Card>
              <div className="text-center">
                <a
                  href="https://app.tutoquiz.com/signup"
                  className="text-decoration-none"
                >
                  <Button color="warning" className="px-5 font-weight-bold">
                    Join
                  </Button>
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Row
        style={{
          backgroundImage: `url(${dailyQuizBg})`,
          backgroundPosition: "50% 20%",
          objectFit: "cover",
        }}
        className="mt-4"
      >
        <Col xs="12" lg="6" md="6">
          <img src={dailyQuizImg} className="img-fluid" alt="dailyQuizImg" />
        </Col>
        <Col
          xs="12"
          lg="6"
          md="6"
          className="d-flex align-items-center justify-content-center text-center pb-4"
        >
          <div className="textHeight">
            <span className="studentBannerText">
              <strong className="highlightText">Daily Quiz</strong>
              <br />
              <br />
              Answer 5 questions daily and <br />
              get rewarded handsomely
            </span>
            <br />
            <a href="https://app.tutoquiz.com/signup" className="">
              <Button color="warning" className="mt-5 px-4 py-3 startedButton">
                <b>Explore Quiz</b>
              </Button>
            </a>
          </div>
        </Col>
      </Row>

      <Card className="planHeading">
        <Row>
          <Col
            xs="12"
            lg="12"
            md="12"
            className="d-flex align-items-center justify-content-center text-center mt-5"
          >
            <CardTitle
              tag="h2"
              style={{ color: "#939393" }}
              className="font-weight-bold"
            >
              Choose a plan that best suits your needs
            </CardTitle>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center planHeading1">
          <Col
            xs="12"
            lg="3"
            md="3"
            className="d-flex align-items-center justify-content-center"
          >
            <Card className="planCard1">
              <CardHeader className="planHeader">
                <Row className="">
                  <Col xs="6">
                    <img src={TQMonogram} className="w-25 h-100" alt="" />
                  </Col>
                  <Col xs="6" className="d-flex justify-content-end">
                    <FaRegHandshake color="#00CCCC" size="24" />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="text-center">
                <CardTitle tag="h2" className="text-info font-weight-bold">
                  BASIC
                </CardTitle>
                {/* <small className="text-center">Home Edition</small> */}
                <CardText className="planSubTitle1 m-2">
                  Questions Only
                </CardText>
                <small className="mt-3">
                  <b>$ 0.00</b> SGD / 3 months
                </small>
              </CardBody>
              <CardFooter className="planFooter">
                <a
                  href="https://app.tutoquiz.com/signup"
                  className="text-decoration-none"
                >
                  <Button color="info" className="font-weight-bold" block>
                    Choose Free
                  </Button>
                </a>
              </CardFooter>
            </Card>
          </Col>
          <Col
            xs="12"
            lg="3"
            md="3"
            className="d-flex align-items-center justify-content-center"
          >
            <Card className="planCard2">
              <CardHeader className="planHeader">
                <Row className="">
                  <Col xs="6">
                    <img src={TQMonogram} className="w-25 h-100" alt="" />
                  </Col>
                  <Col xs="6" className="d-flex justify-content-end">
                    <FaGraduationCap color="#FDAA2B" size="24" />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="text-center">
                <CardTitle tag="h2" className="text-warning font-weight-bold">
                  GOLDEN
                </CardTitle>
                {/* <small className="text-center">Basic Edition</small> */}
                <CardText className="planSubTitle2 m-2">
                  Questions
                  <br />
                  Parent App
                </CardText>
                <small className="mt-3">
                  <b>$ 80.00</b> SGD / 6 months{" "}
                </small>
              </CardBody>
              <CardFooter className="planFooter">
                <a
                  href="https://app.tutoquiz.com/signup"
                  className="text-decoration-none"
                >
                  <Button color="warning" className="font-weight-bold" block>
                    Choose Basic
                  </Button>
                </a>
              </CardFooter>
            </Card>
          </Col>
          <Col
            xs="12"
            lg="3"
            md="3"
            className="d-flex align-items-center justify-content-center"
          >
            <Card className="planCard3">
              <CardHeader className="planHeader">
                <Row className="">
                  <Col xs="6">
                    <img src={TQMonogram} className="w-25 h-100" alt="" />
                  </Col>
                  <Col xs="6" className="d-flex justify-content-end">
                    <FaRegGem color="#E96A6A" size="24" />
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="text-center">
                <CardTitle tag="h2" className="text-danger font-weight-bold">
                  PLATINUM
                </CardTitle>
                {/* <small className="text-center">Advanced Edition</small> */}
                <CardText className="planSubTitle3 m-2">
                  Questions,
                  <br />
                  Parent App <br /> Online Instructor
                </CardText>
                <small className="mt-3">
                  <b>$ 250.00</b> SGD / 12 months
                </small>
              </CardBody>
              <CardFooter className="planFooter">
                <a
                  href="https://app.tutoquiz.com/signup"
                  className="text-decoration-none"
                >
                  <Button color="danger" className="font-weight-bold" block>
                    Choose Advanced
                  </Button>
                </a>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Card>

      <Card
        style={{
          backgroundImage: `url(${TestimonialsBg})`,
          backgroundPosition: "100% 100%",
          objectFit: "cover",
          backgroundSize: "cover",
          padding: "10%",
        }}
        className=""
      >
        <Row className="mb-4">
          <Col xs="12">
            <CardTitle tag="h2" className="font-weight-bold text-center">
              Testimonials
            </CardTitle>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mt-3">
          <Col xs="12" lg="8" md="8" className="alignBorder">
            <Card className="testimonialsCard">
              <Row className="aligntestimonialsCard">
                <Col xs="12" lg="3" md="3">
                  <CardImg
                    className="img-fluid"
                    src={child}
                    alt="Card image cap"
                  />
                </Col>
                <Col xs="">
                  <CardBody>
                    <CardTitle tag="h5">
                      Rahul <small className="text-muted">6th Grade , Sg</small>
                    </CardTitle>
                    <CardSubtitle tag="h6" className="mt-3">
                      It is my best choice to improve my skills and take test
                      myself...
                    </CardSubtitle>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>

      <Row className="mt-5 mb-5">
        <Col
          xs={{ size: 12, offset: 0 }}
          lg={{ size: 12, offset: 0 }}
          md={{ size: 12, offset: 0 }}
          className="d-flex align-items-center justify-content-center"
        >
          <Card className="specializationCard">
            <CardBody>
              <Row>
                <Col
                  xs="12"
                  lg="9"
                  md="9"
                  className="d-flex align-items-center justify-content-center"
                >
                  <CardTitle tag="h3" className="specializationText">
                    Learn Specializtions From Tutoquiz
                  </CardTitle>
                </Col>
                <Col
                  xs="12"
                  lg="3"
                  md="3"
                  className="d-flex align-items-center justify-content-center"
                >
                  <a href="https://app.tutoquiz.com/signup" className="">
                    <Button className="px-4 py-3 chooseSkillsBtn">
                      <b>Sign Up</b>
                    </Button>
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default Student;
