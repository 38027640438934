import React, { useState } from "react";
import DefaultNavbar from "./Navbar";
import {
  Row,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardImg,
  CardBody,
} from "reactstrap";
import ENG1 from "./assets/ENG1.jpg";
import math from "./assets/math.jpg";
import sci from "./assets/sci.jpg";
import ComprehensionCloze from "./assets/02_Comprehension-Cloze.jpg";
import ComprehensionOpenEnded from "./assets/03_Comprehension-Open-ended.jpg";
import EditingSpellingGrammar from "./assets/04_Editing-for-Spelling-&-Grammar.jpg";
import Grammar from "./assets/05_Grammar.jpg";
import GrammarCloze from "./assets/06_Grammar-Cloze.jpg";
import PictureSentence from "./assets/07_Picture-sentence.jpg";
import Punctuation from "./assets/Punctuation.jpg";
import TransformationandSynthesis from "./assets/10_Transformation-and-Synthesis.jpg";
import VisualTextComprehension from "./assets/11_Visual-text-comprehension.jpg";
import Vocabulary from "./assets/12_Vocabulary.jpg";
import VocabularyCloze from "./assets/13_Vocabulary-Cloze.jpg";
import WordOrder from "./assets/14_Word-order.jpg";
import AdditionSubraction from "./assets/AdditionandSubraction.jpg";
import Numbersto100 from "./assets/Numbersto100.jpg";
import Patterns from "./assets/07_Pattern.jpg";
import Shapes from "./assets/08_Shapes.jpg";
import Time from "./assets/09_Time.jpg";
import Graphs from "./assets/02_Graphs.jpg";
import Length from "./assets/03_Length.jpg";
import Money from "./assets/04_Money.jpg";
import MultiplicationandDivision from "./assets/05_Multiplication-&-Division.jpg";
import Cubes from "./assets/Cubes.jpg";
import Queues from "./assets/Queues.jpg";
import PieChart from "./assets/PieChart.jpg";
import Angles from "./assets/Angles.jpg";
import Decimals from "./assets/Decimals.jpg";
import AreaPerimeter from "./assets/AreaandPerimeter.jpg";
import Directions from "./assets/Directions.jpg";
import Fractions from "./assets/Fractions.jpg";
import Parallel from "./assets/Parallel.jpg";
import Symmetry from "./assets/Symmetry.jpg";
import Volume from "./assets/volume.jpg";
import Weight from "./assets/weight.jpg";
import Speed from "./assets/speed.jpg";
import Percentage from "./assets/percentages.jpg";
import Equations from "./assets/Equations.jpg";
import Averages from "./assets/Averages.jpg";
import Rate from "./assets/Rate.jpg";
import Parallelogram from "./assets/Parallelogram.jpg";
import BarGraph from "./assets/BarGraph.jpg";
import Mass from "./assets/Mass.jpg";
import Circulatory from "./assets/Circulatory.jpg";
import FoodEnergy from "./assets/FoodEnergy.jpg";
import FormsEnergy from "./assets/FormsEnergy.jpg";
import FungiandBacteria from "./assets/FungiandBacteria.jpg";
import HumanSystems from "./assets/HumanSystems.jpg";
import Photosynthesis from "./assets/Photosynthesis.jpg";
import PlantReproduction from "./assets/PlantReproduction.jpg";
import WebofLife from "./assets/WebOfLife.jpg";
import Respiratory from "./assets/Respiratory.jpg";
import Electricity from "./assets/Electricity.jpg";
import ParentsToChild from "./assets/ParentsToChildren.jpg";
import Animals from "./assets/01_Animals.jpg";
import AmazingBody from "./assets/AmazingBody.jpg";
import ClassifyThings from "./assets/02_-Classifying-Things.jpg";
import DigestiveSystem from "./assets/03_Digestive-System.jpg";
import ExploringMaterials from "./assets/04_Exploring-Materials.jpg";
import LifeCycle from "./assets/06_Life-cycles-of-Plants-and-Animals.jpg";
import Light from "./assets/07_Light.jpg";
import LivingandNonLiving from "./assets/08_Living-and-Non-living-things.jpg";
import Magnets from "./assets/09_Magnets.jpg";
import Matter from "./assets/10_Matter.jpg";
import PlantSystem from "./assets/12_Plant-System-and-its-parts.jpg";
import Plants from "./assets/13_Plants.jpg";
import WaterCycle from "./assets/15_Water-Life-Cycle.jpg";
import Systems from "./assets/systems.jpg";
import WholeNumbers from "./assets/wholenumbers.jpg";
import Carousel from "react-elastic-carousel";
import { FaRegHandPointRight } from "react-icons/fa";
import Footer from "./Footer";

const SGgrades = () => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const breakPoints = [
    // { width: 550, itemsToShow: 1 }, // Mobile
    { width: 550, itemsToShow: 1, itemsToScroll: 1 },
    { width: 570, itemsToShow: 2 }, //  Ipad
    { width: 666, itemsToShow: 3 }, //  Ipad Pro Sidebar Minimized
    { width: 1024, itemsToShow: 3 }, // Ipad Pro Full Width
    { width: 894, itemsToShow: 4 }, //  Medium Laptops
    { width: 1424, itemsToShow: 5 }, //  Laptop
  ];

  return (
    <>
      <DefaultNavbar />
      <Row>
        <Col xs="12" lg="12" md="12" className="text-center">
          <CardTitle
            tag="h2"
            className="text-dark font-weight-bold text-center sgGradesBg"
          >
            Grades
          </CardTitle>
        </Col>
      </Row>
      <Row>
        <Col xs="12" className="">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  toggle("1");
                }}
              >
                Primary 1
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  toggle("2");
                }}
              >
                Primary 2
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "3" }}
                onClick={() => {
                  toggle("3");
                }}
              >
                Primary 3
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "4" }}
                onClick={() => {
                  toggle("4");
                }}
              >
                Primary 4
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "5" }}
                onClick={() => {
                  toggle("5");
                }}
              >
                Primary 5
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "6" }}
                onClick={() => {
                  toggle("6");
                }}
              >
                Primary 6
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="align-items-center justify-content-center">
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={ENG1}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        English
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={math}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Maths
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <CardTitle
                    tag="h3"
                    className="text-dark font-weight-bold text-center sgGradesBg"
                  >
                    Recommended Topics
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    English Topics ( 13 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    dataInterval={false}
                    enableAutoPlay
                    autoPlaySpeed={3500}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PictureSentence}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Picture Sentence
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Vocabulary}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={GrammarCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VocabularyCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ComprehensionOpenEnded}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Comprehension Open-Ended
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={TransformationandSynthesis}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Transformation and Synthesis
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Grammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VisualTextComprehension}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Visual Text Comprehension
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Punctuation}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Punctuation
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ComprehensionCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Comprehension Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WordOrder}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Word Order
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={EditingSpellingGrammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Editing For Spelling and Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Maths Topics ( 10 )
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Numbersto100}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Numbers to 100
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Time}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Time
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AdditionSubraction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Addition and Subtraction
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Money}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Money
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={MultiplicationandDivision}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Multiplication and Division
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Length}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Length
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Graphs}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Shapes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Shapes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Patterns}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Patterns
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="align-items-center justify-content-center">
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={ENG1}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        English
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={math}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Maths
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <CardTitle
                    tag="h3"
                    className="text-dark font-weight-bold text-center sgGradesBg"
                  >
                    Recommended Topics
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    English Topics ( 9 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={3500}
                    dataInterval={false}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Vocabulary}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={GrammarCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VocabularyCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ComprehensionOpenEnded}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Comprehension Open-Ended
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={TransformationandSynthesis}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Transformation and Synthesis
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Grammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VisualTextComprehension}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Visual Text Comprehension
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={EditingSpellingGrammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Editing For Spelling and Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Maths Topics ( 14 )
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Rate}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Rate
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Time}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Time
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AdditionSubraction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Addition and Subtraction
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Money}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Money
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={MultiplicationandDivision}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Multiplication and Division
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Length}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Length
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Graphs}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Shapes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Shapes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Cubes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Cubes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Patterns}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Patterns
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Numbersto100}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Numbers Upto 10000
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Fractions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fractions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Weight}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Weight
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Volume}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Volume
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="align-items-center justify-content-center">
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={ENG1}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        English
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={math}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Maths
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={sci}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Science
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <CardTitle
                    tag="h3"
                    className="text-dark font-weight-bold text-center sgGradesBg"
                  >
                    Recommended Topics
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    English Topics ( 9 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={3500}
                    dataInterval={false}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Vocabulary}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={GrammarCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VocabularyCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ComprehensionOpenEnded}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Comprehension Open-Ended
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={TransformationandSynthesis}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Transformation and Synthesis
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Grammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VisualTextComprehension}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Visual Text Comprehension
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={EditingSpellingGrammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Editing For Spelling and Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Maths Topics ( 16 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Angles}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Angles
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={BarGraph}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Bar Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WholeNumbers}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Whole Numbers
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Parallel}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Parallel and Perpendicular Lines
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Mass}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Mass
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AreaPerimeter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Area and Perimeter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Rate}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Rate
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Time}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Time
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AdditionSubraction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Addition and Subtraction
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Money}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Money
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={MultiplicationandDivision}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Multiplication and Division
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Length}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Length
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Graphs}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Shapes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Shapes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Cubes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Cubes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Patterns}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Patterns
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Numbersto100}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Numbers Upto 10000
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Fractions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fractions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Weight}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Weight
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Volume}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Volume
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Science Topics ( 13 )
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4100}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={LifeCycle}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Life Cycles Of Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={LivingandNonLiving}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Living and Non-Living
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Magnets}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Magnets
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FungiandBacteria}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fungi and Bacteria
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Matter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Matter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Light}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Light
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={DigestiveSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Digestive System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AmazingBody}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Your Amazing Body
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Plants}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Systems}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Systems
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PlantSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plant System and its Parts
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Animals}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Animals
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ExploringMaterials}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Exploring Materials
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row className="align-items-center justify-content-center">
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={ENG1}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        English
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={math}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Maths
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={sci}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Science
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <CardTitle
                    tag="h3"
                    className="text-dark font-weight-bold text-center sgGradesBg"
                  >
                    Recommended Topics
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    English Topics ( 9 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={3500}
                    dataInterval={false}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Vocabulary}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={GrammarCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>

                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={TransformationandSynthesis}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Transformation and Synthesis
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Grammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VisualTextComprehension}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Visual Text Comprehension
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={EditingSpellingGrammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Editing For Spelling and Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Maths Topics ( 19 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Directions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Directions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Symmetry}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Symmetry
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Percentage}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Percentages
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Averages}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Average
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>

                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Decimals}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Decimals
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>

                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={BarGraph}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Bar Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WholeNumbers}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Whole Numbers
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Parallel}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Parallel and Perpendicular Lines
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Mass}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Mass
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AreaPerimeter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Area and Perimeter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Rate}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Rate
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Time}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Time
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AdditionSubraction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Addition and Subtraction
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Money}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Money
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={MultiplicationandDivision}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Multiplication and Division
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Length}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Length
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Graphs}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Shapes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Shapes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Cubes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Cubes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Patterns}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Patterns
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Numbersto100}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Numbers Upto 10000
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Fractions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fractions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Weight}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Weight
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Volume}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Volume
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Science Topics ( 16 )
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4100}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WaterCycle}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Water Life Cycle
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={HumanSystems}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Human Systems
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FormsEnergy}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Forms and Uses Of Energy
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Respiratory}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Respiratory System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Electricity}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Electricity
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Circulatory}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Circulatory System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PlantReproduction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Reproduction in Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FungiandBacteria}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fungi and Bacteria
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Matter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Matter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Light}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Light
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={DigestiveSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Digestive System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AmazingBody}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Your Amazing Body
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Plants}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Systems}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Systems
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PlantSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plant System and its Parts
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Animals}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Animals
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ExploringMaterials}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Exploring Materials
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="5">
              <Row className="align-items-center justify-content-center">
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={ENG1}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        English
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={math}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Maths
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={sci}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Science
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <CardTitle
                    tag="h3"
                    className="text-dark font-weight-bold text-center sgGradesBg"
                  >
                    Recommended Topics
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    English Topics ( 7 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={3500}
                    dataInterval={false}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Vocabulary}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={GrammarCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>

                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={TransformationandSynthesis}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Transformation and Synthesis
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Grammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VisualTextComprehension}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Visual Text Comprehension
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={EditingSpellingGrammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Editing For Spelling and Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Maths Topics ( 21 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Directions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Directions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Symmetry}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Symmetry
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Percentage}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Percentages
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Averages}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Average
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Parallelogram}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Parallelograms
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Decimals}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Decimals
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Angles}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Angles
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={BarGraph}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Bar Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WholeNumbers}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Whole Numbers
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Parallel}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Parallel and Perpendicular Lines
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Mass}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Mass
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AreaPerimeter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Area and Perimeter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Rate}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Rate
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Time}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Time
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AdditionSubraction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Addition and Subtraction
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Money}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Money
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={MultiplicationandDivision}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Multiplication and Division
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Length}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Length
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Graphs}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Shapes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Shapes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Cubes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Cubes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Patterns}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Patterns
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Numbersto100}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Numbers Upto 10000
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Fractions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fractions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Weight}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Weight
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Volume}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Volume
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Science Topics ( 20 )
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4100}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WaterCycle}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Water Life Cycle
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={HumanSystems}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Human Systems
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FormsEnergy}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Forms and Uses Of Energy
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Respiratory}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Respiratory System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Electricity}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Electricity
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Circulatory}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Circulatory System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PlantReproduction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Reproduction in Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={LifeCycle}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Life Cycles Of Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={LivingandNonLiving}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Living and Non-Living
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Magnets}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Magnets
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FungiandBacteria}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fungi and Bacteria
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Matter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Matter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Light}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Light
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={DigestiveSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Digestive System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AmazingBody}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Your Amazing Body
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Plants}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Systems}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Systems
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PlantSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plant System and its Parts
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Animals}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Animals
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ExploringMaterials}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Exploring Materials
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
            </TabPane>

            <TabPane tabId="6">
              <Row className="align-items-center justify-content-center">
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={ENG1}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        English
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={math}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Maths
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="3">
                  <Card className="m-2">
                    <CardImg
                      top
                      width="100%"
                      height="auto"
                      src={sci}
                      alt="SubTopic"
                      className="img-fluid"
                    />
                    <CardBody>
                      <CardTitle
                        tag="h5"
                        className="text-primary font-weight-bold text-center"
                      >
                        Science
                      </CardTitle>
                      <p />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12" className="text-center">
                  <CardTitle
                    tag="h3"
                    className="text-dark font-weight-bold text-center sgGradesBg"
                  >
                    Recommended Topics
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    English Topics ( 9 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={3500}
                    dataInterval={false}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Vocabulary}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VocabularyCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Vocabulary Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={GrammarCloze}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar Cloze
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>

                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={TransformationandSynthesis}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Transformation and Synthesis
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ComprehensionOpenEnded}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Comprehension Open-Ended
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Grammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={VisualTextComprehension}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Visual Text Comprehension
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={EditingSpellingGrammar}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Editing For Spelling and Grammar
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col
                  xs={{ size: 10, offset: 1 }}
                  lg={{ size: 3, offset: 0 }}
                  md={{ size: 3, offset: 0 }}
                  className="ml-3"
                >
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Maths Topics ( 26 )
                  </CardTitle>
                </Col>
              </Row>
              <Row>
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4000}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Speed}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Speed
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PieChart}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Pie Charts
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Queues}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Queues
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src="
                        https://app.tutoquiz.com/b2e1cb5808d732275326508947626a86.jpg"
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Circles
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Equations}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Linear Equations
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Directions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Directions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Symmetry}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Symmetry
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Percentage}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Percentages
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Averages}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Average
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Parallelogram}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Parallelograms
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Decimals}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Decimals
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Angles}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Angles
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={BarGraph}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Bar Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WholeNumbers}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Whole Numbers
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Parallel}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Parallel and Perpendicular Lines
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Mass}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Mass
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AreaPerimeter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Area and Perimeter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Rate}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Rate
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Time}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Time
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AdditionSubraction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Addition and Subtraction
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Money}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Money
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={MultiplicationandDivision}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Multiplication and Division
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Length}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Length
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Graphs}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Graphs
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Shapes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Shapes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Cubes}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Cubes
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Patterns}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Patterns
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Numbersto100}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Numbers Upto 10000
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Fractions}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fractions
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Weight}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Weight
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Volume}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Volume
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col xs="12" lg="3" md="3" className="ml-3">
                  <CardTitle
                    tag="h5"
                    className="text-dark font-weight-bold text-center"
                  >
                    <FaRegHandPointRight
                      size="24"
                      color="#ff6600"
                      className="mr-2"
                    />
                    Science Topics ( 25 )
                  </CardTitle>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs={{ size: 10, offset: 1 }}>
                  <Carousel
                    itemsToShow={5}
                    pagination={false}
                    enableAutoPlay
                    autoPlaySpeed={4100}
                    focusOnSelect={true}
                    enableTilt={true}
                    breakPoints={breakPoints}
                  >
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WebofLife}
                        alt="SubTopic"
                        className="img-fluid"
                      />

                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Web Of Life
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ClassifyThings}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Classifying Things
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FoodEnergy}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Eergy in Food
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Photosynthesis}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Photosynthesis
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ParentsToChild}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          From Parents To Young
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>

                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={WaterCycle}
                        alt="SubTopic"
                        className="img-fluid"
                      />

                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Water Life Cycle
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={HumanSystems}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Human Systems
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FormsEnergy}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Forms and Uses Of Energy
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Respiratory}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Respiratory System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Electricity}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Electricity
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Circulatory}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Circulatory System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PlantReproduction}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Reproduction in Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={LifeCycle}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Life Cycles Of Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={LivingandNonLiving}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Living and Non-Living
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Magnets}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Magnets
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={FungiandBacteria}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Fungi and Bacteria
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Matter}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Matter
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Light}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Light
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={DigestiveSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Digestive System
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={AmazingBody}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Your Amazing Body
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Plants}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plants
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Systems}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Systems
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={PlantSystem}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Plant System and its Parts
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={Animals}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Animals
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                    <Card className="m-2">
                      <CardImg
                        top
                        width="100%"
                        height="72px"
                        src={ExploringMaterials}
                        alt="SubTopic"
                        className="img-fluid"
                      />
                      <CardBody>
                        <CardTitle
                          tag="h6"
                          className="text-primary font-weight-bold text-center"
                        >
                          Exploring Materials
                        </CardTitle>
                        <p />
                      </CardBody>
                    </Card>
                  </Carousel>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
      <Footer />
    </>
  );
};
export default SGgrades;
