import React from "react";
import {
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  Button,
  Card,
  CardImg,
  CardBody,
} from "reactstrap";
import DefaultNavbar from "./Navbar";
import ParentBanner2 from "./assets/ParentBanner2.jpg";
import Learning from "./assets/Learning.svg";
import HowItWorks from "./assets/HowItWorks.svg";
import Quizzes from "./assets/Quizzes.svg";
import TestimonialsBg from "./assets/TestimonialsBg.jpg";
import child from "./assets/child.svg";
// import Carousel from "react-elastic-carousel";
import Footer from "./Footer";

const Parent = () => {
  return (
    <>
      <DefaultNavbar />
      <Row className="alignImgText">
        <Col xs="12" md="6" lg="6" className="imgBg px-0">
          <div className="textHeight">
            <span className="studentBannerText">
              Take your <br />
              <strong className="highlightText">Child’s Future</strong>
              <br /> into your own hands
            </span>
            <br />
            <br />
            <small className="text-light font-weight-bold">
              Bond with Tutoquiz to identify your <br />
              child’s weak areas and fix them instantly
            </small>
            <br />
            <a href="https://app.tutoquiz.com/signup">
              <Button color="warning" className="mt-5 px-5 py-3 startedButton">
                <b>Get Started</b>
              </Button>
            </a>
          </div>
        </Col>
        <Col xs="12" md="6" lg="6" className="px-0">
          <img src={ParentBanner2} className="img-fluid" alt="parentbanner" />
        </Col>
      </Row>
      <Card style={{ backgroundColor: "#F6FFFF" }}>
        <Row className="align-items-center justify-content-center mt-3 text-center">
          <Col xs="12" md="6" lg="6">
            <img
              src={HowItWorks}
              alt="parent_img_three"
              className="img-fluid"
            />
          </Col>
          <Col xs="12" md="5" lg="5" className="parentContent">
            <CardTitle tag="h2" className="parentHeading mt-2">
              <div className="parentApp">
                How it works ...?
                <div
                  style={{
                    width: "20%",
                    height: "15px",
                    borderBottom: "3px solid #006666",
                  }}
                />
              </div>
            </CardTitle>
            <CardSubtitle tag="h6" className="parentText mt-4 text-left">
              Tutoquiz uses machine learning to identify the individual needs
              and skill level of each child. Instead of bombarding the child
              with irrelevant questions, Tutoquiz identifies his weaknesses and
              moderates the questions so that he can learn at his own pace.
              Gradually, the child grasps the concept by solving the relevant
              questions posted by Tutoquiz and moves on to the next level.
            </CardSubtitle>
          </Col>
        </Row>
      </Card>
      <Card style={{ backgroundColor: "#00A4AA" }} className="p-5">
        <Row>
          <Col
            xs={{ size: 12, offset: 0 }}
            lg={{ size: 8, offset: 2 }}
            md={{ size: 8, offset: 2 }}
            className="border p-5"
          >
            <Row>
              <Col
                xs="12"
                lg="9"
                md="9"
                className="d-flex align-items-center justify-content-center"
              >
                <CardTitle tag="h3" className="text-white customBuilt">
                  Custom built dashboards to keep track of your kids progress
                  closely
                </CardTitle>
              </Col>
              <Col
                xs="12"
                lg="3"
                md="3"
                className="d-flex align-items-center justify-content-center mt-2"
              >
                <a
                  href="https://app.tutoquiz.com/signup"
                  className="text-decoration-none"
                >
                  <Button color="warning" className="px-4 py-2 startedButton">
                    <b>Sign Up</b>
                  </Button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Card style={{ backgroundColor: "#FFF5F0" }}>
        <Row className="align-items-center justify-content-center mt-3 text-center alignImgText">
          <Col xs="12" md="5" lg="5" className="parentContent">
            <CardTitle tag="h2" className="parentHeading mt-2">
              <div className="parentApp">
                The Traditional Method...
                <div
                  style={{
                    width: "12%",
                    height: "15px",
                    borderBottom: "3px solid #006666",
                  }}
                />
              </div>
            </CardTitle>
            <CardSubtitle tag="h6" className="parentText mt-4 text-left">
              Every child is special in his own way. He has his own skill set
              and specific needs. He may be good in one subject or topic but
              weak in another. Without going into the specifics, the traditional
              model uses a one-size-fits-all approach to learning. Unable to
              cope up with the cookie-cutter approach, many children fall in
              between the cracks, thus remaining as failures in life.
            </CardSubtitle>
          </Col>
          <Col xs="12" md="6" lg="6">
            <img src={Learning} alt="parent_img_four" className="img-fluid" />
          </Col>
        </Row>
      </Card>
      <Card style={{ backgroundColor: "#18B3CF" }}>
        <Row className="align-items-center justify-content-center mt-3 text-center">
          <Col xs="12" md="5" lg="5">
            <img src={Quizzes} alt="parent_img_five" className="img-fluid" />
          </Col>
          <Col xs="12" md="5" lg="5" className="parentContent">
            <CardTitle tag="h2" className="parentHeading1">
              <div className="parentApp">
                Parent App !!
                <div
                  style={{
                    width: "32%",
                    height: "15px",
                    borderBottom: "3px solid #000",
                  }}
                />
              </div>
            </CardTitle>
            <CardSubtitle tag="h6" className="parentText1 mt-4 text-left">
              Every parent is worried about the future of his child. They are
              ready to spend enormous amounts of time and money as they don’t
              have the tools to do it themselves. Not anymore. With Tutoquiz
              Parent App, you get all you need for your child to succeed at your
              fingertips. Flanked with custom designed dashboards and analytics,
              Tutoquiz metamorphosizes you into an expert who can generate
              worksheets in his weak areas and monitor the progress closely.
              Tutoquiz sends you daily reports on his progress which you can use
              to make the necessary changes.
            </CardSubtitle>
          </Col>
        </Row>
      </Card>
      <Card
        style={{
          backgroundImage: `url(${TestimonialsBg})`,
          backgroundPosition: "100% 100%",
          objectFit: "cover",
          backgroundSize: "cover",
          padding: "10%",
        }}
        className=""
      >
        <Row className="mb-4">
          <Col xs="12">
            <CardTitle tag="h2" className="font-weight-bold text-center">
              Testimonials
            </CardTitle>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mt-3">
          <Col xs="12" lg="8" md="8" className="alignBorder">
            <Card className="testimonialsCard">
              <Row className="aligntestimonialsCard">
                <Col xs="12" lg="3" md="3">
                  <CardImg
                    className="img-fluid"
                    src={child}
                    alt="Card image cap"
                  />
                </Col>
                <Col xs="">
                  <CardBody>
                    <CardTitle tag="h5">
                      Rahul <small className="text-muted">6th Grade , Sg</small>
                    </CardTitle>
                    <CardSubtitle tag="h6" className="mt-3">
                      It is my best choice to improve my skills and take test
                      myself...
                    </CardSubtitle>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Card>
      <Footer />
    </>
  );
};
export default Parent;
